import axios from 'axios';
import log from 'loglevel';
import { notification } from 'antd';
import { toJson } from 'shared/json';
import _ from 'lodash';

export default function setupAxios() {  
  // request interceptor
  axios.interceptors.request.use(async (request) => {
    log.debug(
      'axios:request',
      request.method,
      request.url,
      request.data || request.params
    );
    
    return request;
  });
  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      log.error('axios:response.error', { error });
      const info = error.response?.data?.info ?? {};
      if (!_.isObject(info.msg)) {
        notification.error({
          message: info.code || error.message,
          description: info.msg || defaultHttpErrorMessage(error),
        });
      }
      return Promise.reject(error.response?.data);
    }
  );
}

function defaultHttpErrorMessage(error) {
  return error.response ? toJson(error.response.data) : '';
}
