import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from 'setup/reportWebVitals';
import setupAxios from 'setup/setupAxios';
import './index.css';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";
import ScrollToTop from 'page/main/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';

const App = lazy(() => import('./App'));

const helmetContext = {};

(async () => {
  setupAxios();

  ReactDOM.render(
    // <Router>
    //   <Suspense fallback={null}>
    //     <App />
    //   </Suspense>
    // </Router>
    <HelmetProvider context={helmetContext}>
      <HistoryRouter history={createBrowserHistory()}>
        <ScrollToTop>
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </ScrollToTop>
      </HistoryRouter>
    </HelmetProvider>
    ,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
